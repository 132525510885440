/** @jsx jsx */
import { jsx } from "theme-ui"
import "./reset.css"
import { useStaticQuery, graphql } from "gatsby"
import { CSSReset, Footer } from "../components"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <CSSReset />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
