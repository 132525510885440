/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"

const ListItem = React.memo(({ active, children }) => {
  return (
    <div
      sx={{
        padding: 16,
        borderRadius: "small",
        backgroundColor: active ? "primary.100" : "white",
        cursor: "pointer",
        ":hover": { backgroundColor: active ? "primary.100" : "gray.100" },
      }}
    >
      {children}
    </div>
  )
})

export default ListItem
