/** @jsx jsx */
import { jsx } from "theme-ui"

const linkeHeading = Tag => props => {
  if (!props.id) {
    return <Tag {...props} />
  }

  return (
    <Tag {...props}>
      <a href={`#${props.id}`} sx={{ color: "currentColor" }}>
        {props.children}
      </a>
    </Tag>
  )
}

const components = {
  h1: linkeHeading("h1"),
  h2: linkeHeading("h2"),
  h3: linkeHeading("h3"),
  h4: linkeHeading("h4"),
  h5: linkeHeading("h5"),
  h6: linkeHeading("h6"),
}
export default components
