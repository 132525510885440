/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Container, ExternalLink } from "../components"
import { keyframes } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const taglineIntro = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px) scale(1.03);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const imgIntro = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10px) scale(0.98);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
`

const imgTextIntro = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5px) scale(1.03);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const s = {
  animation: {
    animationTimingFunction: "cubic-bezier(0, 0.66, 0.77, 1.08)",
    animationDuration: "850ms",
    animationFillMode: "both",
  },
}

const CTA = ({ children, ...props }) => (
  <ExternalLink
    to="https://sf.gov/oceia"
    sx={{
      height: [48, 56],
      borderRadius: "small",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(to bottom, #1472ff, #136bee)",
      boxShadow: "inset 0 0 1px 1px rgba(0, 0, 0, 0.15)",
    }}
    {...props}
  >
    {children}
  </ExternalLink>
)

const Hero = () => {
  const { background } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "crowd.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      sx={{
        backgroundColor: "white",
        height: ["auto", "80vh"],
        position: "relative",
      }}
    >
      <Container sx={{ height: "100%" }}>
        <div
          sx={{
            height: "100%",
            display: ["block", "flex"],
            position: "relative",
            paddingTop: [48, 0],
            "> * + *": {
              marginTop: [16, 0],
            },
          }}
        >
          <div sx={{ variant: "flex.centerY" }}>
            <div sx={{ position: "relative", zIndex: 1 }}>
              <Styled.h1
                sx={{
                  marginBottom: "0.4em",
                  fontSize: [800, 900],
                  animationName: `${taglineIntro}`,
                  ...s.animation,
                }}
              >
                We all count.
              </Styled.h1>
              <Styled.h3
                sx={{
                  color: "text.body",
                  marginBottom: "1.25em",
                  animation: `${taglineIntro}`,
                  animationDelay: "200ms",

                  ...s.animation,
                }}
              >
                The 2020 Census has ended. <br /> Thank you for participating{" "}
                <br /> in the count with us!
              </Styled.h3>
              <CTA
                sx={{
                  width: ["100%", "60%"],
                  animation: `${taglineIntro}`,
                  animationDelay: "350ms",

                  ...s.animation,
                }}
              >
                <Styled.h4 sx={{ color: "white" }}>Stay connected</Styled.h4>
              </CTA>
            </div>
          </div>
          <div
            sx={{
              width: "95%",
              position: ["static", "absolute"],
              height: ["auto", "95%"],
              overflow: ["hidden", "visible"],
              right: "-35%",
              bottom: "-6%",
              variant: "flex.centerXY",
              animationName: `${imgIntro}`,
              animationDelay: "500ms",
              ...s.animation,
            }}
          >
            <Img
              fluid={background.childImageSharp.fluid}
              style={{
                width: "100%",
              }}
              imgStyle={{
                objectFit: "cover",
              }}
            ></Img>
            <Styled.h2
              sx={{
                position: "absolute",
                fontSize: [300, 700],
                animationName: `${imgTextIntro}`,
                animationDelay: "470ms",
                ...s.animation,
              }}
            >
              We counted!
            </Styled.h2>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Hero
