const headingStyles = {
  fontFamily: "primary",
  lineHeight: "heading",
  fontWeight: "heading",
  letterSpacing: "heading",
  color: "text.heading",
}

const textStyles = {
  color: "text.body",
  fontFamily: "primary",
  fontWeight: "body",
  lineHeight: "body",
  letterSpacing: "body",
}

const typography = {
  text: {
    hero: {
      ...headingStyles,
      fontSize: [700, 900],
      fontWeight: "hero",
      lineHeight: 1.05,
    },
    title: {
      ...headingStyles,
      fontSize: [600, 700],
      lineHeight: 1.25,
    },
    heading: {
      ...headingStyles,
      fontSize: [500, 600],
    },
    subheading: {
      ...headingStyles,
      fontSize: [300, 400],
      lineHeight: "body",
    },
    smallHeading: {
      ...headingStyles,
      fontSize: [200, 300],
      lineHeight: "body",
    },
    meta: {
      ...headingStyles,
      fontSize: 100,
      textTransform: "uppercase",
      letterSpacing: "caps",
      fontWeight: "medium",
      color: "text.muted",
    },
    bodyLarge: {
      ...textStyles,
      fontSize: [300, 400],
    },
    body: {
      ...textStyles,
      fontSize: 300,
    },
    medium: {
      ...textStyles,
      fontSize: 200,
    },
    small: {
      ...textStyles,
      fontSize: 100,
      color: "text.muted",
    },
  },
}

export default typography
