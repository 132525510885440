/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"
import { Container } from "../components"

const linkStyles = {
  variant: "links.base",
}

const Footer = () => {
  return (
    <div sx={{ backgroundColor: "white" }}>
      <Container
        sx={{
          variant: ["flex.column", "flex.spaceBaseline"],
          paddingY: 32,
          flexDirection: ["column-reverse", "row"],
          "> * + *": { marginBottom: [32, 0] },
        }}
      >
        <div sx={{ color: "text.medium", color: "text.muted" }}>
          © City & County of San Francisco
        </div>
        <div
          sx={{
            variant: ["flex.column", "flex.centerY"],
            alignItems: "flex-start",
            flexDirection: ["column", "row"],
            "> a + a": { marginLeft: [0, 32], marginTop: [24, 0] },
          }}
        >
          <Styled.a href="/resources" sx={linkStyles}>
            Community Resources
          </Styled.a>
          <Styled.a href="/contact" sx={linkStyles}>
            Contact
          </Styled.a>
          <Link to="/disclaimer" sx={linkStyles}>
            Disclaimer
          </Link>
          <Link to="/privacy" sx={linkStyles}>
            Privacy Policy
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default Footer
