import React from "react"
import { Global } from "@emotion/core"

export default () => {
  return (
    <Global
      styles={theme => ({
        body: {
          fontFamily: theme.fonts.primary,
          letterSpacing: theme.letterSpacings.body,
          lineHeight: theme.lineHeights.body,
        },
        img: {
          width: "100%",
          display: "block",
        },
        a: {
          color: "unset",
        },
      })}
    />
  )
}
