/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Container, ExternalLink } from "../components"
import { Info } from "../components/icons"

const callCenters = [
  {
    language: "English",
    phone: "888-268-6820",
    website: "Lawyers Committee for Civil Rights Under Law ",
    url: "https://lawyerscommittee.org/census2020/",
  },
  {
    language: "Spanish",
    phone: "877-352-3676",
    website: "NALEO Educational Fund",
    url: "https://hagasecontar.org/",
  },
  {
    language: "Arabic",
    phone: "888-268-6820",
    website: "Arab American Institute",
    url: "https://yallacountmein.org/",
  },
  {
    language: "Cantonese, Korean, Mandarin, Tagalog, Vietnamese",
    phone: "844-202-0274",
    website: "Asian Americans Advancing Justice",
    url: "https://www.countusin2020.org/",
  },
]

const AlertBanner = () => (
  <section
    sx={{
      backgroundColor: "background.lightest",
      width: "100%",
      paddingY: [40, 72],
    }}
  >
    <Container>
      <div sx={{ position: "relative" }}>
        <Info
          sx={{
            color: "orange",
            width: 24,
            height: 24,
            position: ["static", "absolute"],
            left: -32,
            top: 8,
          }}
        />

        <div>
          <Styled.h3 sx={{ marginBottom: [24, 32] }}>
            Census Help Centers are closed or operating{" "}
            <br sx={{ display: ["none", "none", "block"] }} /> at a limited
            capacity at this time.
          </Styled.h3>
        </div>
      </div>
      <Styled.p sx={{ marginBottom: [24, 32] }}>
        Get your 2020 Census questions answered from trusted community partners.
        <br />
        Call one of these multilingual hotlines with questions:
      </Styled.p>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 200px)",
          gridGap: 24,
        }}
      >
        {callCenters.map(c => (
          <div
            key={c.url}
            sx={{
              "* + *": {
                marginTop: 8,
              },
              a: { color: "text.body" },
            }}
          >
            <Styled.h4>{c.language}</Styled.h4>
            <div>
              <Styled.a href={`tel:${c.phone}`}>{c.phone}</Styled.a>
            </div>
            <div>
              <Styled.a href={c.url}>{c.website}</Styled.a>
            </div>
          </div>
        ))}
      </div>
    </Container>
  </section>
)

export default AlertBanner
