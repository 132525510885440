/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"
import { Container } from "../components"

const censusFacts = [
  {
    color: "primary.500",
    title: "People",
    description:
      "The census uplifts the voices of our families, friends and neighbors in San Francisco. The power to shape our future is in our hands. We ALL count!",
  },
  {
    color: "orange",
    title: "Power",
    description:
      "The 2020 Census will decide how many seats California will have in the U.S. House of Representatives. Doing the census ensures that you and all San Franciscans have fair representation in government.",
  },
  {
    color: "green",
    title: "Money",
    description:
      "Census data helps decide how billions of dollars will reach our communities. When you do the census, it helps our city get money for schools, hospitals, roads, social services and more.",
  },
]

const WhatCensus = () => {
  return (
    <div sx={{ backgroundColor: "white" }}>
      <Container
        sx={{
          paddingY: [16, 72],
        }}
      >
        <div
          sx={{
            "> * + *": { marginTop: 16 },
            marginBottom: [56, 96],
            position: "relative",
          }}
        >
          <Styled.h2>What is the Census?</Styled.h2>
          <Styled.p sx={{ maxWidth: "60ch" }}>
            The census is a count the federal government does of every person
            living in the United States. It happens once every 10 years and is
            required by the&nbsp;US&nbsp;Constitution.
          </Styled.p>
          <Link
            to="/census-faq"
            sx={{
              variant: "links.inline",
              display: "inline-block",
              marginTop: 16,
            }}
          >
            Learn more &#8594;
          </Link>
        </div>
        <div>
          <Styled.h4 sx={{ marginBottom: 24 }}>
            The census plays an important role in our daily lives and is about:
          </Styled.h4>
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
              gridGap: [20, 48],
            }}
          >
            {censusFacts.map(f => (
              <div key={f.title}>
                <Styled.h3 sx={{ marginBottom: 12, color: f.color }}>
                  {f.title}
                </Styled.h3>
                <p sx={{ variant: "text.body", lineHeight: 1.55 }}>
                  {f.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default WhatCensus
