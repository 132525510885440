/** @jsx jsx */
import { jsx } from "theme-ui"
import { SFSeal } from "."
import { ExternalLink, Container } from "../components"
import { FacebookIcon, InstagramIcon, TwitterIcon } from "./icons"

const links = [
  {
    url: "https://www.facebook.com/sfgov.oceia/",
    Component: FacebookIcon,
  },
  {
    url: "https://www.instagram.com/sf_immigrants/",
    Component: InstagramIcon,
  },
  {
    url: "https://twitter.com/OCEIA_SF",
    Component: TwitterIcon,
  },
]

export const SocialLinks = () => (
  <div sx={{ variant: "flex.centerY" }}>
    {links.map(({ url, Component }) => (
      <ExternalLink
        to={url}
        key={url}
        sx={{
          variant: "flex.centerY",
          "& + &": { marginLeft: 24 },
          ":hover": { boxShadow: "none" },
        }}
      >
        <Component
          sx={{
            color: "gray.600",
            fill: "currentColor",
            ":hover": { color: "gray.700" },
            ":active": { color: "gray.800" },
          }}
        ></Component>
      </ExternalLink>
    ))}
  </div>
)

const ServiceContactInfo = () => {
  return (
    <div sx={{ backgroundColor: "background.lightest" }}>
      <Container
        sx={{
          variant: "flex.columnX",
          paddingY: 72,
          maxWidth: 500,
          textAlign: "center",
        }}
      >
        <div sx={{ width: 72, marginBottom: 16 }}>
          <SFSeal />
        </div>
        <p sx={{ variant: "text.body" }}>
          This service is built in partnership with the
          <br />
          <ExternalLink
            to="https://sf.gov/departments/city-administrator/office-civic-engagement-and-immigrant-affairs"
            sx={{ color: "text.heading" }}
          >
            Office of Civic Engagement & Immigrant Affairs
          </ExternalLink>
        </p>
        <div sx={{ marginY: 16 }}>
          <SocialLinks></SocialLinks>
        </div>
        <p sx={{ variant: "text.medium", marginBottom: 8 }}>
          Not all the views expressed by partner organizations on this website
          or related links are those of the City and County of San Francisco.
        </p>
        <p>
          <ExternalLink to="http://bit.ly/sfcensushelporgs">
            Fill out this form to have your organization included on this site.
          </ExternalLink>
        </p>
      </Container>
    </div>
  )
}

export default ServiceContactInfo
