/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

const ExternalLink = ({ to, children, ...props }) => {
  return (
    <Styled.a href={to} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </Styled.a>
  )
}

export default ExternalLink
