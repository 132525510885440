/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import GoogleMapReact from "google-map-react"
import mapStyles from "./mapStyles"
import { useMapContextActions, useMapContextState } from "../components"

const Marker = React.memo(({ handleClick, activeStyles, org }) => {
  return (
    <div
      sx={{ ...s.marker, ...activeStyles }}
      onClick={handleClick}
      title={org}
    ></div>
  )
})

const Map = React.memo(({ nodes, focusedNode }) => {
  const { handleMarkerSelect } = useMapContextActions()
  const { selectedNode } = useMapContextState()

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.GATSBY_GMAPS }}
      defaultCenter={{ lat: 37.769247, lng: -122.4716127 }}
      defaultZoom={13}
      yesIWantToUseGoogleMapApiInternals
      options={{
        styles: mapStyles,
        gestureHandling: "cooperative",
        clickableIcons: false,
      }}
    >
      {nodes.map(n => {
        return (
          <Marker
            key={n.id}
            lat={n.lat}
            lng={n.lng}
            org={n.org}
            handleClick={e => {
              e.stopPropagation()
              handleMarkerSelect(n.id)
            }}
            activeStyles={
              n.id == selectedNode
                ? markerHover
                : n.id == focusedNode
                ? markerHover
                : {}
            }
          ></Marker>
        )
      })}
    </GoogleMapReact>
  )
})

export default Map

const markerHover = {
  backgroundColor: "orange",
  width: 20,
  height: 20,
  zIndex: 5,
}

const s = {
  marker: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 16,
    height: 16,
    borderRadius: "50% 50% 50% 0",
    boxShadow: "elevation3",
    backgroundColor: "primary.500",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    cursor: "pointer",
    transition: "all 100ms ease-in-out",
    ":hover": markerHover,
    "::after": {
      position: "absolute",
      content: "''",
      width: "8px",
      height: "8px",
      background: "#fff",
      borderRadius: "round",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
}
