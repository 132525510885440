import typography from "./typography"

const { text } = typography

const palette = {
  blackRGB: "24, 32, 38",
  primary: {
    50: "#eff6fb",
    100: "#e3eef7",
    200: "#81DEFD",
    300: "#5ED0FA",
    400: "#40C3F7",
    500: "#2c7ffd",
    600: "#1472ff",
    700: "#1863d6",
    800: "#0B69A3",
    900: "#035388",
  },
  gray: {
    50: "#f7fbfc",
    100: "#eef4f7",
    200: "#e6ebef",
    300: "#cad1d8",
    400: "#a9b2bc",
    500: "#89939f",
    600: "#6d747e",
    700: "#53585f",
    800: "#3c3f44",
    900: "#16181d",
    950: "#0b0c0f",
  },
}

const borderShadowColor = `rgba(${palette.blackRGB},.1)`
const blurryShadowColor = `rgba(${palette.blackRGB},.15)`

const elevations = {
  elevation0: `0 0 1px ${borderShadowColor}`,
  elevation1: `0 0 1px ${borderShadowColor}, 0 2px 4px -2px ${blurryShadowColor}`,
  elevation2: `0 0 1px ${borderShadowColor}, 0 5px 8px -4px ${blurryShadowColor}`,
  elevation3: `0 0 1px ${borderShadowColor}, 0 8px 10px -4px ${blurryShadowColor}`,
  elevation4: `0 0 1px ${borderShadowColor}, 0 16px 24px -8px ${blurryShadowColor}`,
}

export default {
  colors: {
    ...palette,
    green: "#0fbb5d",
    orange: "#ff3927",
    background: {
      lightest: palette.gray["50"],
      light: palette.gray["100"],
      grayAlpha1: "#1027430f",
      primaryAlpha1: "#1472ff0f",
      primaryAlpha2: "#1472ff1a",
    },
    text: {
      heading: palette.gray["950"],
      body: palette.gray["800"],
      muted: palette.gray["500"],
      caption: palette.gray["400"],
    },
  },
  borders: {
    base: `1px solid ${palette.gray["100"]}`,
    muted: `1px solid ${palette.gray["200"]}`,
  },
  fonts: {
    primary:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  radii: { small: "4px", big: "8px", round: "9999px" },
  fontSizes: {
    50: 11,
    100: 12,
    200: 14,
    300: 16,
    400: 20,
    500: 24,
    600: 30,
    700: 36,
    800: 48,
    900: 64,
  },
  space: {
    gutter: 20,
  },
  sizes: {
    container: 960,
  },
  fontWeights: {
    body: 400,
    medium: 500,
    heading: 600,
    hero: 700,
  },
  letterSpacings: {
    body: "-0.015em",
    heading: "-0.028em",
    caps: "0.06em",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    ...elevations,
  },
  breakpoints: ["800px"],
  text,
  links: {
    base: {
      fontSize: [200, 300],
      color: "text.heading",
      fontWeight: "medium",
      letterSpacing: "initial",

      ":hover": {
        boxShadow: "0 1px 0 currentColor",
      },
    },
    inline: {
      color: "primary.600",

      ":hover": {
        boxShadow: "0 1px 0 currentColor",
      },
    },
  },
  layout: {
    container: {
      width: "100%",
      maxWidth: 960,
      paddingX: "gutter",
      marginX: "auto",
    },
  },
  flex: {
    centerX: {
      display: "flex",
      justifyContent: "center",
    },
    centerY: {
      display: "flex",
      alignItems: "center",
    },
    baseline: {
      display: "flex",
      alignItems: "baseline",
    },
    centerXY: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    columnX: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    space: {
      display: "flex",
      justifyContent: "space-between",
    },
    spaceY: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    spaceBaseline: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
    },
    inlineXY: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  patterns: {
    fullAbsolute: {
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
    },
  },
  styles: {
    root: {
      fontFamily: "primary",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      ...text.hero,
    },
    h2: {
      ...text.title,
    },
    h3: {
      ...text.heading,
    },
    h4: {
      ...text.subheading,
    },
    h5: {
      ...text.smallHeading,
    },
    h6: {
      ...text.meta,
    },
    p: {
      ...text.bodyLarge,
    },
    a: {
      color: "primary.600",

      ":hover": {
        boxShadow: "0 1px 0 currentColor",
      },
    },
    img: {
      maxWidth: "100%",
    },
    hr: {
      border: "muted",
      marginX: 0,
    },
    ul: {
      listStyle: "disc",
      paddingLeft: "1.25em",
      ...text.bodyLarge,

      "> li + li": {
        marginTop: 16,
      },
    },
  },
}
