import React from "react"

const MapContext = React.createContext()

const Actions = {
  InputChange: "InputChange",
  FilterChange: "FilterChange",
  HandleMarkerSelect: "HandleMarkerSelect",
}

const initialState = {
  nodes: [],
  selectedNode: null,
  query: "",
  filters: {
    language: [],
    lgbt: [],
    unhoused: [],
    accessibility: [],
  },
}

const mapReducer = (state, { type, payload }) => {
  switch (type) {
    case Actions.InputChange:
      return {
        ...state,
        query: payload,
      }
    case Actions.HandleMarkerSelect:
      return {
        ...state,
        selectedNode: payload,
      }
    case Actions.FilterChange:
      const { key, value } = payload
      let current = state.filters[key]

      if (current.includes(value)) {
        current = current.filter(c => c !== value)
      } else {
        current = [...current, value]
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: current,
        },
      }
    default:
      break
  }
}

const MapProvider = ({ nodes, children }) => {
  const [state, dispatch] = React.useReducer(mapReducer, {
    ...initialState,
    nodes,
  })

  const handleInputChange = React.useCallback(e => {
    dispatch({ type: Actions.InputChange, payload: e.currentTarget.value })
  })

  const handleFilterChange = React.useCallback(filterValue => {
    dispatch({ type: Actions.FilterChange, payload: filterValue })
  })

  const handleMarkerSelect = React.useCallback(id => {
    dispatch({ type: Actions.HandleMarkerSelect, payload: id })
  })

  console.log("state", state)

  return (
    <MapContext.Provider
      value={{
        state,
        actions: { handleInputChange, handleFilterChange, handleMarkerSelect },
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export const useMapContextState = () => React.useContext(MapContext).state
export const useMapContextActions = () => React.useContext(MapContext).actions

export default MapProvider
