/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import {
  Map,
  MapProvider,
  useMapContextState,
  useMapContextActions,
  ListItem,
  Container,
} from "../components"
import { filter } from "fuzzaldrin-plus"

const s = {
  filterLayout: {
    display: "flex",
    flexWrap: "wrap",
    margin: -8,
    marginTop: 4,
  },
  checkboxLabel: {
    display: "inline-flex",
    alignItems: "center",
    variant: "text.medium",
    margin: 8,
    whiteSpace: "nowrap",
    flexBasis: 92,
    cursor: "pointer",
  },
  listPanelStyles: {
    position: ["static", "absolute"],
    top: -32,
    left: 24,
    height: [650, "calc(100% + 64px)"],
    width: ["100%", 375],
    boxShadow: "elevation4",
    borderRadius: "big",
    variant: "flex.column",
    zIndex: 10,
    backgroundColor: "white",
    overflowY: "auto",
    border: "base",
  },
}

const LocationItem = React.memo(
  React.forwardRef(
    (
      { org, address, phone, language, hours, website, handleFocus, id },
      ref
    ) => {
      return (
        <div
          ref={ref}
          sx={{ "> * + *": { marginTop: 8 } }}
          onMouseEnter={() => handleFocus(id)}
          onMouseLeave={() => handleFocus(null)}
        >
          <h3
            sx={{
              marginBottom: 8,
              color: "text.body",
              fontWeight: "heading",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={org}
          >
            {org}
          </h3>
          {language && (
            <div sx={{ variant: "flex.baseline" }}>
              <div sx={{ variant: "text.medium" }}>🌍{language}</div>
            </div>
          )}
          <div sx={{ variant: "text.medium" }}>{address}</div>
          <div
            sx={{
              variant: "text.medium",
              color: "text.muted",
            }}
          >
            {hours}
          </div>
          <div
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Styled.a
              href={`tel:+${phone}`}
              sx={{ display: "inline-block", fontSize: 200 }}
            >
              {phone}
            </Styled.a>
            <Styled.a href={`//${website}`} sx={{ variant: "text.meta" }}>
              visit website
            </Styled.a>
          </div>
        </div>
      )
    }
  )
)

const SearchField = () => {
  const { handleInputChange } = useMapContextActions()
  const { query } = useMapContextState()

  return (
    <div sx={{ position: "sticky", top: 0, borderBottom: "muted" }}>
      <input
        type="text"
        sx={{
          paddingX: 16,
          variant: "text.medium",
          caretColor: "#1992D4",
          ":focus": {
            backgroundColor: "background.lightest",
          },
        }}
        placeholder="Search…"
        onChange={handleInputChange}
        value={query}
      />
    </div>
  )
}

const SectionHeader = () => (
  <div>
    <Styled.h6>Get Census Help</Styled.h6>
    <Styled.h2>
      Find a San Francisco Census <br />
      Help Center in your neighborhood.
    </Styled.h2>
  </div>
)

const getUniqueLanguages = list => {
  const languages = list.reduce((accum, current) => {
    if (current.language && current.language.trim().length) {
      return [...accum, ...current.language.trim().split(", ")]
    }

    return accum
  }, [])

  return Array.from(new Set(languages))
}

const Filter = ({ list, title, isOpen = false }) => {
  console.log("Filter -> list", list)
  const { handleFilterChange } = useMapContextActions()

  return (
    <details open={isOpen}>
      <summary sx={{ color: "text.body", cursor: "pointer" }}>
        <Styled.h6 sx={{ display: "inline-block", color: "primary.600" }}>
          {title}
        </Styled.h6>
      </summary>
      <div sx={s.filterLayout}>
        {list.map(d => {
          let item =
            typeof d === "string" ? { title: d, value: d, key: "language" } : d

          return (
            <label key={item.title} htmlFor={item.title} sx={s.checkboxLabel}>
              <input
                type="checkbox"
                id={item.title}
                sx={{ marginRight: 6, cursor: "pointer" }}
                onChange={() =>
                  handleFilterChange({ key: item.key, value: item.value })
                }
              />
              {item.title}
            </label>
          )
        })}
      </div>
    </details>
  )
}

const filterList = ({ list, query, keys = [] }) => {
  return filter(list, query, { key: "org", ...keys })
}

const LocationList = React.memo(({ list, children }) =>
  list.map((n, i) => children(n, i))
)

const MapContainer = () => {
  const { nodes, query, filters, selectedNode } = useMapContextState()
  const { handleMarkerSelect } = useMapContextActions()
  const [hoveredItem, setHoveredItem] = React.useState()
  const languages = React.useMemo(() => getUniqueLanguages(nodes), [])
  const selectedRef = React.useRef()
  const listRef = React.useRef()
  const mapContainerRef = React.useRef()

  React.useEffect(() => {
    if (selectedRef.current && listRef.current) {
      listRef.current.scrollTo({
        top: selectedRef.current.offsetTop - 100,
        behavior: "smooth",
      })
    }
  }, [selectedRef, selectedNode, listRef])

  const filteredNodes = React.useMemo(() => {
    if (
      filters.language.length == 0 &&
      filters.lgbt.length == 0 &&
      filters.unhoused.length == 0 && 
      filters.accessibility.length == 0
    ) {
      return nodes
    }

    return nodes.filter(n => {
      if (!n.language) {
        return false
      }

      const nodeLanguages = n.language.trim().split(", ")

      const hasLanguage = nodeLanguages.some(l => filters.language.includes(l))
      let hasLgbt
      let hasUnhoused
      let hasAccessibility

      if (filters.lgbt.length > 0) {
        hasLgbt = !!n.lgbt.trim().length
      }
      if (filters.unhoused.length > 0) {
        hasUnhoused = !!n.unhoused.trim().length
      }
      if (filters.accessibility.length > 0) {
        hasAccessibility = !!n.accessibility.trim().length
      }

      return hasLanguage || hasLgbt || hasUnhoused || hasAccessibility
    })
  }, [nodes, filters])

  const displayedNodes = React.useMemo(
    () => (query ? filterList({ list: filteredNodes, query }) : filteredNodes),
    [query, filteredNodes]
  )

  return (
    <div
      sx={{ backgroundColor: "white", paddingY: [48, 72] }}
      onClick={e => {
        if (mapContainerRef.current.contains(e.target)) {
          return
        }

        handleMarkerSelect(null)
      }}
    >
      <Container sx={{ marginBottom: [24, 56] }}>
        <SectionHeader></SectionHeader>
      </Container>
      <Container sx={{ maxWidth: t => t.sizes.container + 120 }}>
        <div
          sx={{
            position: "relative",
            height: ["auto", "75vh"],
            minHeight: ["auto", "550px"],
          }}
        >
          <div sx={s.listPanelStyles} ref={listRef}>
            <SearchField />
            <div sx={{ padding: 16, paddingBottom: 0 }}>
              <Filter list={languages} title="Language Filter"></Filter>
            </div>
            <div sx={{ padding: 16, paddingBottom: 0 }}>
              <Filter
                list={[
                  { key: "lgbt", title: "LGBTQ+", value: 1 },
                  { key: "unhoused", title: "Homelessness", value: 1 },
                  { key: "accessibility", title: "Accessibility", value: 1 },
                ]}
                title="Special Focus Filter"
                isOpen={true}
              ></Filter>
            </div>
            <div
              sx={{
                padding: 4,
                height: "100%",
                flex: 1,
                "> * + *": {
                  borderTop: "muted",
                },
              }}
            >
              {displayedNodes.length !== 0 ? (
                <LocationList list={displayedNodes}>
                  {item => {
                    if (!item.org) {
                      return null
                    }

                    return (
                      <ListItem key={item.id} active={item.id === selectedNode}>
                        <LocationItem
                          {...item}
                          handleFocus={setHoveredItem}
                          ref={item.id === selectedNode ? selectedRef : null}
                        />
                      </ListItem>
                    )
                  }}
                </LocationList>
              ) : (
                <div sx={{ padding: 24, variant: "flex.centerXY" }}>
                  <Styled.h6>No results found.</Styled.h6>
                </div>
              )}
            </div>
          </div>
          <div
            sx={{
              height: "100%",
              display: ["none", "block"],
              borderRadius: "big",
              overflow: "hidden",
            }}
            ref={mapContainerRef}
          >
            <Map nodes={displayedNodes} focusedNode={hoveredItem} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default () => {
  const {
    data: { nodes: helpCenters },
  } = useStaticQuery(mapContainerQuery)

  return (
    <MapProvider nodes={helpCenters}>
      <MapContainer></MapContainer>
    </MapProvider>
  )
}

export const mapContainerQuery = graphql`
  query {
    data: allMarchUpdatesCsv {
      nodes {
        address
        hours
        language
        lat
        lng
        org
        phone
        website
        lgbt
        unhoused
        accessibility
        accessibility
        id
      }
    }
  }
`
