/** @jsx jsx */
import { jsx } from "theme-ui"

const Stack = props => {
  return (
    <div
      sx={{
        "> * + *": {
          marginTop: [24, 48],
        },
        "p + p, li + li, p + ul": {
          marginTop: "1em",
        },
        "h3 + *": {
          marginTop: 16,
        },
        "h4 + h1, h4 + h2": {
          marginTop: 8,
        },
      }}
      {...props}
    ></div>
  )
}

export default Stack
