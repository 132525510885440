/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"
import { Container, SFCountsSVG } from "../components"
import { ArtAction } from "../components/icons"

const helpCenters = {
  spanish: [
    {
      title: "Good Samaritan Family Resource Center",
      subtitle: "(Centro de Recursos Familiares del Buen Samaritano)",
      phone: "415-401-4251",
      address: "1294 Potrero Avenue San Francisco CA 94110",
    },
    {
      title: "Mission Economic Development Center",
      subtitle: "(Centro de Desarrollo Económico de la Misión)",
      phone: "415-288-4334",
      address: "2301 Mission Street, San Francisco, CA 94110",
    },
  ],
  chinese: [
    {
      title: "華人權益促進會",
      phone: "電話：415-275-6760",
      address: "地址：三藩市華埠林華耀街（Walter U Lum Place）17號",
    },
    {
      title: "安老自助處",
      phone: "電話：415-677-7500",
      address: "地址：三藩市華埠昃臣街（Jackson St）601號",
    },
  ],
  filipino: [
    {
      title: "South of Market Community Action Network (SOMCAN)",
      phone: "415-255-7693",
      address: "1038 Mission Street, San Francisco, CA 94103",
    },
    {
      title: "Bayanihan Equity Center",
      phone: "(415) 255-2347",
      address: "1010 Mission Street, Suite C, San Francisco, CA 94103",
    },
  ],
}

export const MDXContainer = props => (
  <Container>
    <div sx={{ maxWidth: "75ch" }} {...props}></div>
  </Container>
)

export const FlexSpace = props => (
  <div sx={{ variant: "flex.space" }} {...props}></div>
)

export const PromoVideo = () => (
  <iframe
    width="100%"
    sx={{ borderRadius: "small", overflow: "hidden", height: [250, 400] }}
    src="https://www.youtube.com/embed/LjQWjBTU9zc"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
)

const HelpCenter = ({ title, subtitle, phone, address }) => (
  <div
    sx={{
      padding: [16, 24],
      borderRadius: "small",
      border: "base",
      "> * + *": { marginTop: 16 },
    }}
  >
    <Styled.h4>{title}</Styled.h4>
    {subtitle && <Styled.p>{subtitle}</Styled.p>}
    <address sx={{ "> * + *": { marginTop: 16 } }}>
      <div sx={{ variant: "text.body" }}>{address}</div>
      <Styled.a href={`tel:+${phone}`} sx={{ display: "inline-block" }}>
        {phone}
      </Styled.a>
    </address>
  </div>
)

export const HelpCenters = ({ language }) => {
  const centers = helpCenters[language]
  return (
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", "1fr 1fr"],
        gridGap: [24, 48],
        width: "100%",
      }}
    >
      {centers.map(c => (
        <HelpCenter key={`${c.title}-${c.phone}`} {...c} />
      ))}
    </div>
  )
}

export const Supporters = () => {
  return (
    <React.Fragment>
      <div sx={s.stack}>
        <SFCountsSVG width={160}></SFCountsSVG>
        <Styled.p>
          Knowledge is power! Our shared folder has social media graphics and
          toolkits, presentations, fact sheets and more available for you.
          <Styled.a sx={s.link} href="https://bit.ly/sfcountsresources">
            Get the resources and share &#8594;
          </Styled.a>
        </Styled.p>
      </div>
      <div sx={s.stack}>
        <ArtAction width={160} />
        <Styled.p>
          Take action! Our partners at Art+Action have built the first-ever San
          Francisco coalition for civic participation across art, creative,
          community, business, technology, philanthropy, and government sectors.
          <Styled.a sx={s.link} href="http://cometoyourcensus.us/">
            Learn more about the movement and how to volunteer &#8594;
          </Styled.a>
        </Styled.p>
      </div>
    </React.Fragment>
  )
}

export const ContactInfo = () => (
  <React.Fragment>
    <div sx={{ display: "flex" }}>
      <span sx={{ fontSize: 400 }} role="img" aria-label="location pin">
        📍
      </span>
      <div sx={{ marginLeft: 16 }}>
        <Styled.h4>Office of Civic Engagement and Immigrant Affairs</Styled.h4>
        <Styled.p>
          50 Van Ness Ave <br />
          San Francisco, CA 94102
        </Styled.p>
      </div>
    </div>
    <div sx={{ display: "flex" }}>
      <span sx={{ fontSize: 400 }} role="img" aria-label="mail">
        ✉️
      </span>
      <div sx={{ marginLeft: 16 }}>
        <Styled.h4>Email</Styled.h4>
        <Styled.p>
          SF Counts
          <Styled.a
            sx={{ display: "block" }}
            href="mailto:sf2020.census@sfgov.org"
          >
            sf2020.census@sfgov.org
          </Styled.a>
        </Styled.p>
      </div>
    </div>
  </React.Fragment>
)

const s = {
  stack: {
    flex: 1,
    "> * + *": {
      marginTop: 16,
    },
  },
  link: {
    display: "inline-block",
    marginTop: 12,
  },
}
