import React from "react"

const SvgComponent = props => (
  <svg viewBox="0 0 102 102" fill="none" {...props}>
    <path
      d="M50.53 101.283c27.685 0 50.537-22.851 50.537-50.488 0-27.686-22.901-50.537-50.586-50.537C22.843.258.04 23.109.04 50.795c0 27.637 22.852 50.488 50.488 50.488zm-.294-67.04c-3.662 0-6.64-3.028-6.64-6.69 0-3.76 2.978-6.739 6.64-6.739 3.76 0 6.69 2.979 6.69 6.739 0 3.662-2.93 6.69-6.69 6.69zm-8.35 43.066c-2.148 0-3.906-1.612-3.906-3.809 0-2.1 1.758-3.76 3.907-3.76h5.86V49.38h-4.981c-2.198 0-3.907-1.563-3.907-3.809 0-2.1 1.71-3.76 3.907-3.76h9.326c2.734 0 4.199 1.954 4.199 4.883V69.74h5.42c2.197 0 3.906 1.66 3.906 3.76 0 2.197-1.709 3.809-3.906 3.809H41.887z"
      fill="currentColor"
    />
  </svg>
)

export default SvgComponent
