import { Link } from "gatsby"
/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { SFCountsSVG, Container } from "../components"

const Links = () => (
  <div
    sx={{
      display: "flex",
      "> * + *": { marginLeft: 32 },
    }}
  >
    <Link to="/about-sfcounts" sx={style.navLink}>
      About SF Counts
    </Link>
    <Link to="/census-faq" sx={style.navLink}>
      Census FAQ
    </Link>
    <Link to="/resources" sx={style.navLink}>
      Community Resources
    </Link>
  </div>
)

const LanguageLinks = () => (
  <div
    sx={{
      backgroundColor: "background.light",
      display: "flex",
      paddingY: 4,
      paddingX: 24,
      borderRadius: "round",
      "> * + *": { marginLeft: 32 },
    }}
  >
    <Link to="/spanish" sx={style.navLink}>
      Español
    </Link>
    <Link to="/chinese" sx={style.navLink}>
      中文
    </Link>
    <Link to="/filipino" sx={style.navLink}>
      Filipino
    </Link>
  </div>
)

const Header = () => (
  <header
    sx={{
      backgroundColor: `white`,
    }}
  >
    <Container>
      <div sx={{ variant: ["block", "flex.space"], width: "100%" }}>
        <Link
          to="/"
          sx={{
            display: "flex",
            alignItems: "flex-end",
            ":hover": { opacity: 0.85 },
          }}
        >
          <SFCountsSVG sx={{ width: [120, 160] }} />
        </Link>
        <div
          sx={{
            variant: "flex.column",
            alignItems: ["flex-end"],
            marginTop: [24, 0],
          }}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 16,
            }}
          >
            <LanguageLinks></LanguageLinks>
          </div>
          <Links></Links>
        </div>
      </div>
    </Container>
  </header>
)

export default Header

const style = {
  navLink: {
    variant: "links.base",
  },
}
